import React from 'react';
import { Link } from 'gatsby';
import useEmblaCarousel from 'embla-carousel-react';
import cx from 'classnames';

import { StandardCard } from '../cards/standardCard';
import { Navigation } from './navigation';
import { InfoCard } from '../cards/infoCard';

export const StandardCarousel = ({
  slider,
  reversed,
  className,
  label,
  viewAll,
  importantDates,
}) => {
  /* Carousel & button handling */
  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);

  const [viewportRef, embla] = useEmblaCarousel({
    containScroll: true,
    align: 'start',
    dragFree: true,
  });

  const onSelect = React.useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const scrollPrev = React.useCallback(
    () => embla && embla.scrollPrev(),
    [embla],
  );
  const scrollNext = React.useCallback(
    () => embla && embla.scrollNext(),
    [embla],
  );

  React.useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  const buttonFunctions = {
    scrollPrev,
    scrollNext,
    prevBtnEnabled,
    nextBtnEnabled,
  };

  return (
    <div className={`px-gutter relative z-10 ${className}`}>
      <div className='flex justify-between items-center'>
        <div className='text-teal lg:flex gap-x-8 items-center'>
          <h3
            className={cx('blockH3', {
              'text-white': reversed,
              'text-teal': !reversed,
            })}>
            {label}
          </h3>
          {viewAll && (
            <Link
              to={`/${viewAll}`}
              className='blockH7 font-bold mt-2 lg:mt-4 block hover:opacity-50 transition-standard'>
              View all
            </Link>
          )}
        </div>
        <Navigation {...buttonFunctions} reversed={reversed} />
      </div>

      <div ref={viewportRef} className='mt-14'>
        <div className='flex flex-row gap-x-4 items-center'>
          {importantDates
            ? importantDates?.map((card, i) => <InfoCard key={i} {...card} />)
            : slider?.slides?.map((card, i) => (
                <StandardCard key={i} {...card} />
              ))}
        </div>
      </div>
    </div>
  );
};
