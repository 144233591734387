import React from 'react';

import ModuleWrapper from '../moduleWrapper';
import { StandardCarousel } from '../carousels/standardCarousel';
import { useGlobalQuery } from '@querys/useGlobalModules';

const ExploreSlider = ({ config, reversed, className }) => {
  const { exploreSlides } = useGlobalQuery();
  const slider = { slides: exploreSlides };
  return (
    <ModuleWrapper {...config}>
      <StandardCarousel
        slider={slider}
        label='Explore Music'
        reversed={reversed}
        className={className}
      />
    </ModuleWrapper>
  );
};

export default ExploreSlider;
