import React from 'react';

export const InfoCard = ({ date, label, location }) => {
  return (
    <div
      className={`w-[316px] md:w-[500px] bg-white self-stretch rounded-3xl overflow-hidden text-teal md:hover:scale-[1.015] transition-standard`}
      style={{ flex: '0 0 auto' }}>
      <div className='px-6 md:px-10 py-6 md:py-10'>
        <span className='blockH7 font-bold'>{label}</span>
        <h4 className='blockH4 mt-3 mb-5'>{date}</h4>
        <span className='blockH7'>{location}</span>
      </div>
    </div>
  );
};
