import React from 'react';
import cx from 'classnames';

import ArrowWhite from '@svg/arrow-white.svg';
import Arrow from '@svg/arrow.svg';

export const Navigation = ({
  prevBtnEnabled,
  nextBtnEnabled,
  scrollNext,
  scrollPrev,
  reversed,
}) => {
  return (
    <div
      className={cx(
        'bg-opacity-10 rounded-full flex gap-x-5 lg:gap-x-8 items-center',
        {
          'bg-teal': !reversed,
          'bg-white': reversed,
        },
      )}>
      <button
        className={`py-3 lg:py-4 pl-3 lg:pl-4 transition-standard ${
          !prevBtnEnabled && 'opacity-50'
        }`}
        onClick={scrollPrev}>
        {reversed ? (
          <ArrowWhite className='w-5 lg:w-8 rotate-180' />
        ) : (
          <Arrow className='w-5 lg:w-8 rotate-180' />
        )}
      </button>
      <button
        className={`py-3 lg:py-4 pr-3 lg:pr-4 transition-standard ${
          !nextBtnEnabled && 'opacity-50'
        }`}
        onClick={scrollNext}>
        {reversed ? (
          <ArrowWhite className='w-5 lg:w-8' />
        ) : (
          <Arrow className='w-5 lg:w-8' />
        )}
      </button>
    </div>
  );
};
