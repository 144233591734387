import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useGlobalQuery = () => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query {
      sanityGlobals {
        exploreSlides {
          pageLink
          position
          title
          excerpt
          featuredImage {
            ...ImageWithPreview
          }
          page {
            ...PageRef
          }
          cardType {
            ...Artist
            ...Blog
            ...Event
            ...Venue
          }
          accentColor {
            hex: value
          }
        }
        featuredLinksLabel
        featuredLinks {
          image {
            ...ImageWithPreview
          }
          link {
            ... on SanityPageLink {
              _key
              _type
              page {
                ...PageRef
              }
              linkText
            }
            ... on SanityDonateButton {
              _key
              _type
              linkText
              category {
                title
                copy: _rawCopy(resolveReferences: { maxDepth: 10 })
              }
            }
          }
        }
      }
    }
  `);
  return sanityGlobals || {};
};
