import React, { useEffect } from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

import { useFilters } from '@lib/context';

const ModuleWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
  setAnimate,
}) => {
  // Padding Classes
  const paddingClasses = cx(className && className, {
    'pt-space-s': paddingTop === 'small',
    'pt-space-m': paddingTop === 'medium',
    'pt-space-l': paddingTop === 'large',
    'pb-space-s': paddingBottom === 'small',
    'pb-space-m': paddingBottom === 'medium',
    'pb-space-l': paddingBottom === 'large',
  });

  //setActiveModule
  const { updateActiveModule } = useFilters();

  const { ref, inView } = useInView({
    threshold:
      typeof window !== 'undefined' && window?.innerWidth > 768 ? 0.5 : 0.3,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      setAnimate && setAnimate(true);
    }
    if (inView && slug?.current) {
      updateActiveModule(slug?.current);
    }
  }, [inView]);

  return (
    <section
      ref={ref}
      className={`${paddingClasses} relative z-1`}
      id={slug && slug?.current}>
      {children}
    </section>
  );
};

export default ModuleWrapper;
